import React, { useEffect, useState } from "react";
import sideBarData from "./SidebarData/SidebarData.jsx";
import { ArrowLeft, ArrowRight, RotateCcw, Clock } from "lucide-react";
import { Link } from "react-router-dom";
import Qrlogo from "../../assets/QR24Logo.webp";
import SkeletonLoader from "../global/skeleton/SkeletonLoader.jsx";
import { removeLocalData, setLocalData } from "../../utils/localData.js";
import userPicture from "../../assets/Profile.webp";
import SidebarProfile from "../SidebarProfile.jsx";
import { useLocation } from "react-router-dom";
import Button from "../global/Button.jsx";
import { MOCK_DELAY_TIMER } from "../../constants/GLOBAL.js";
import { setIsSidebarOpen as setIsSidebarOpen2 } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import TrialExpiryModal from "./TrialExpiryModal.jsx";
import { calculateTrialRemainingTime } from "../../store/slices/subscriptionSlice.js";

const Sidebar = () => {
  const { user, hasBuyAnySubscription } = useSelector(
    (state) => state.root.USER_DATA_SLICE
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const [openTrialExpiryModal, setOpenTrialExpiryModal] = useState(false);
  const subscription = useSelector((state) => state.root.SUBSCRIPTION_SLICE);
  const remainingTime = subscription?.trial_remaining_time;
  const isTrialValid = subscription?.isFreeTrialValid;
  const subscriptionType = subscription?.type;
  const subscriptionStatus = subscription?.currentSubscriptionStatus;

  const [profiledata, setProfileData] = useState({
    // name: user?.name,
    image: userPicture,
    accountType: "",
  });

  // Subscription status has to be updated accordingly
  const isSubscribed = true;

  const hasFreeTrial =
    subscriptionType === "trial" &&
    hasBuyAnySubscription === false &&
    remainingTime > 0 &&
    isTrialValid === true;

  useEffect(() => {
    if (hasFreeTrial) {
      setProfileData((prev) => ({
        ...prev,
        accountType: "Free Account",
      }));
    } else {
      setProfileData((prev) => ({
        ...prev,
        accountType: "Premium Account",
      }));
    }
  }, [hasFreeTrial]);

  const [isSideBarOpen, setIsSideBarOpen] = useState(() =>
    localStorage.getItem("isSidebarOpen")
      ? JSON.parse(localStorage.getItem("isSidebarOpen"))
      : true
  );
  const [activeLink, setActiveLink] = useState("");
  const [loading, setLoading] = useState(() => {
    return !localStorage.getItem("sidebarLoaded");
  });

  const calculateTime = (ms) => {
    const days = Math.floor(ms / 86400000);
    const hours = Math.floor((ms % 86400000) / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return { days, hours, minutes, seconds };
  };
  const { days, hours, minutes, seconds } = calculateTime(remainingTime);
  useEffect(() => {
    setLocalData("isSidebarOpen", isSideBarOpen);
    dispatch(setIsSidebarOpen2(isSideBarOpen));
  }, [isSideBarOpen]);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
        setLocalData("sidebarLoaded", "true");
      }, MOCK_DELAY_TIMER);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    const todays = new Date().getTime();

    if (isSubscribed) {
      setActiveLink("/new-qr");
    } else {
      setActiveLink("/plans");
    }
    const handleBeforeUnload = () => {
      removeLocalData("sidebarLoaded");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return loading ? (
    <SkeletonLoader variant="sidebar" />
  ) : (
    <>
      <div className=" ">
        <div
          className={`sticky top-0 bg-[var(--darker-bg)] !z-[1] ${
            isSideBarOpen ? "w-[290px]" : "w-[100px]"
          } transition-all duration-300 ease-in-out`}
        >
          <div className=" flex flex-col h-[100dvh] py-1 shadow-md top-0 bg-[var(--darker-bg)] overflow-auto">
            <div className="flex flex-col items-center px-2 py-5 mb-1 ">
              <div
                className="bg-[var(--primary)]"
                onClick={() => setIsSideBarOpen(!isSideBarOpen)}
              >
                <Button
                  className={`cursor-pointer absolute right-10 top-5 transition-all duration-300`}
                >
                  <div className="flex h-5 justify-center items-center bg-[var(--primary)]">
                    {isSideBarOpen ? (
                      <ArrowLeft size={20} color="black" />
                    ) : (
                      <ArrowRight size={20} color="black" />
                    )}
                  </div>
                </Button>
              </div>
              <div className="h-24 mt-1 flex justify-center items-center">
                <img
                  src={Qrlogo}
                  alt="QR Logo"
                  className={`transition-all duration-300 ${
                    isSideBarOpen ? "opacity-100" : "opacity-0"
                  }`}
                />
              </div>
            </div>

            <ul className="flex flex-col gap-3">
              {sideBarData.map((item, index) => {
                console.log();
                const isActive =
                  (location.pathname &&
                    location.pathname.includes("dashboard") &&
                    location.pathname.includes(item.route)) ||
                  location.pathname.includes(item.route);

                return (
                  <Link
                    to={isSubscribed ? item.route : "/plans"}
                    key={index}
                    className={`relative flex items-center text-[var(--text-light)] py-3 px-6 cursor-pointer whitespace-nowrap transition-all duration-200 group ${
                      !isActive
                        ? "opacity-50 hover:opacity-80 hover:bg-[#164f545d]"
                        : "opacity-100"
                    }`}
                    onClick={() => setActiveLink(item.route)}
                  >
                    <span
                      className={`absolute left-0 top-0 h-full w-1 bg-[var(--primary)] transition-all duration-300 
                      ${
                        isActive
                          ? "opacity-100"
                          : "opacity-0 group-hover:opacity-60"
                      }`}
                    ></span>

                    {isActive && (
                      <span className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-[var(--primary)] to-transparent opacity-50 group-hover:opacity-50 transition-all duration-300"></span>
                    )}
                    <div
                      className={`text-xl flex justify-center items-center transition-transform duration-300 ${
                        !isSideBarOpen && "mx-auto"
                      }`}
                    >
                      {item.icon}
                    </div>

                    <p
                      className={`ml-2 text-[20px] font-semibold whitespace-nowrap transition-all duration-300 relative z-10 ${
                        isSideBarOpen
                          ? "opacity-100 w-auto"
                          : "opacity-0 w-0 hidden"
                      }`}
                    >
                      {item.title}
                    </p>
                  </Link>
                );
              })}
            </ul>
            <div className="flex flex-col gap-2 mt-auto">
              {hasFreeTrial && (
                <div
                  className={`flex flex-col gap-2 ${
                    isSideBarOpen ? "gap-2" : "gap-[10px]"
                  } `}
                >
                  <div className={`flex items-center justify-center gap-2`}>
                    <Clock size={20} color={"#fff"} />
                    {isSideBarOpen && (
                      <p className="text-white text-[14px]">
                        {days} days remaining
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-center">
                    <Button
                      text={isSideBarOpen ? "Upgrade" : null}
                      bgColor={"var(--primary)"}
                      width={"fit-content"}
                      padding={"8px 20px"}
                      paddingLeft={"20px"}
                      paddingRight={"20px"}
                      rounded={"25px"}
                      icon={<RotateCcw size={20} />}
                      iconOrder={"left"}
                      type={"button"}
                      onClick={() => setOpenTrialExpiryModal(true)}
                    />
                  </div>
                </div>
              )}
              <SidebarProfile
                profileData={profiledata}
                isSideBarOpen={isSideBarOpen}
              />
            </div>
          </div>
        </div>
      </div>
      {openTrialExpiryModal && (
        <TrialExpiryModal
          days={days}
          hours={hours}
          minutes={minutes}
          setModalState={setOpenTrialExpiryModal}
          seconds={seconds}
        />
      )}
    </>
  );
};

export default Sidebar;
