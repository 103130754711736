/* eslint-disable react-refresh/only-export-components */

import React from "react";
import QRTemplate from "../components/shared/templates";
import newQrpage from "../Pages/newQrpage/newQrpage.jsx";
import MenuTesting from "../Pages/newQrpage/newQrpage.jsx";
import BlogDetail from "../Pages/Blog_Page/Blog.Detail.jsx";
import withSkeletonLoader from "../Pages/qrCode-on/withSkeletonLoader.jsx";

// UnAuthenticatedRoutes
export const unAuthenticatedRoutesConstant = {
  INDEX: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  PLANS_AND_PRICES: "/plans-and-prices",
  FAQ: "/faq",
  API: "/api",
  TYPE_OF_QR: "/type-of-qr",
  QR_CODES_FOR: "/qr-codes-for",
  QR_CODES_ON: "/qr-codes-on",
  STATIC_QR_CODES: "/static-qr-codes",
  DYNAMIC_QR_CODES: "/dynamic-qr-codes",
  GENERAL_DATA_PROTECTION: "/general-data-protection",
  BATCH_CREATION_AND_DOWNLOAD: "/batch-creation-and-download",
  MULTIPLE_CDOWNALOD_FORMATS: "/multiple-download-formats",
  CUSTOM_DOMAIN: "/custom-domain",
  CONTACT_FORM: "/contact-us",
  DESCRIPTION: "/description/:id",
  INSTRUCTIONS: "/instructions",
  USER_PROFILE: "/user",
  PREVIEW: "/p/:id",
  PREVIEW_REDIRECT: "/:id",
  DYNAMIC_QR_INFO: "/qr_codes_on/:cardname",
  // MY_QR_CODES: "/my-qr-codes",
  TYPES_OF_QR: "/types-of-qr",
  QR_CODES_ON: "/qr-code-on",
  MY_DOMAIN: "/my-domain",
  MY_DOMAIN: "/my-domain",
  DYNAMIC_QR: "/dynamic-qr",
  QR_TEMPLATE: "/template",
  MenuTesting: "/testing/:id",
  QR_CODES_DESCRIPTION: "/qr-description",
  WHERE_TO_USE: "/where-to-use",
  REVIEWS: "/reviews",
  Privacy_Policy: "/privacy-policy",
  TERMS_OF_USE: "/terms-use",

  Account_Payment: "/account-payment",
  Affiliate: "/affiliate",

  HELP_CENTER: "/help-center",
  BLOG: "/blog",
  BlogDetail: "/blog-detail/:id",
  about_us: "/about-us",
  Aboutus: "/Aboutus",
  BENEFITS: "/benefits",
};

const IndexPage = React.lazy(() => import("../Pages/Home/index.jsx"));
const MY_QR_CODES = React.lazy(() => import("../Pages/Myqrcodes"));
const LoginPage = React.lazy(() => import("../Pages/login/index.jsx"));
// const TestingMenuPage = React.lazy(() =>
//   import("../pages/newQrpage/newQrpage.jsx")
// );

const SignUpPage = React.lazy(() => import("../Pages/signup/index.jsx"));
const DashboardPage = React.lazy(() =>
  import("../components/shared/dashboard/index.jsx")
);
const QRTemplatePage = React.lazy(() =>
  import("../components/shared/templates")
);
const TypesOfQRPage = React.lazy(() => import("../Pages/typesOfQr"));
const DomainPage = React.lazy(() => import("../Pages/MyDomain"));
const QrCodeOnPage = React.lazy(() => import("../Pages/qrCode-on"));
const ApiPage = React.lazy(() => import("../Pages/apiPage"));
const DynamicQRPage = React.lazy(() => import("../Pages/dynamic-qr"));
const StaticQRCodesPage = React.lazy(() => import("../Pages/static-qr"));
const DataProtectionPage = React.lazy(() => import("../Pages/dataProtection"));
const BatchCreationAndDownloadPage = React.lazy(() => import("../Pages/batch-creation-and-download"));
const MultipleDownloadFormatsPage = React.lazy(() => import("../Pages/multiple-download-formats"));
const CustomDomainPage = React.lazy(() => import("../Pages/custom-domain"));
const PlansPage = React.lazy(() => import("../Pages/PricingAndPlans"));
const FAQPage = React.lazy(() => import("../Pages/FAQ"));
const QRDescriptionPage = React.lazy(() => import("../Pages/qrdescription"));

const privacyPolicy = React.lazy(() => import("../Pages/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("../Pages/TermsOfUse"));
const ForgotPasswordPage = React.lazy(() => import("../Pages/forgotPassword"));
const blogdetails = React.lazy(() =>
  import("../Pages/Blog_Page/Blog.Detail.jsx")
);

const ResetPasswordPage = React.lazy(() => import("../Pages/resetPassword"));
const preview = React.lazy(() => import("../Pages/preview"));
const ScanCountPage = React.lazy(() => import("../Pages/ScanCount"));

// const ForgotPasswordPage = React.lazy(() =>
// import("./../container/landing/auth/forgotPassword")
// )
const about_us = React.lazy(() => import("../Pages/about_us/AboutUs.jsx"));

// const ResetPasswordPage = React.lazy(() =>
//     import("./../container/landing/auth/resetPassword")
// );
const PlansAndPricePage = React.lazy(() => import("../pages/plans/index.jsx"));
const BlogPage = React.lazy(() => import("../Pages/Blog_Page/index.jsx"));

// const ApiPage = React.lazy(() => import("./../container/landing/api"));
// const TypeOfQRPage = React.lazy(() =>
//     import("./../container/landing/resources/index")
// );
const QRCodesForPage = React.lazy(() => import("../Pages/qrFor"));
// const QRCodesOnPage = React.lazy(() =>
//     import("./../container/landing/resources/qrCodeOn")
// );
// const StaticQRCodesPage = React.lazy(() =>
//     import("./../container/landing/features/staticQrCodes")
// );
const DynamicQRCodesPage = React.lazy(() => import("../Pages/dynamic-qr"));
// const GeneralDataProtectionsPage =
// React.lazy(() =>
//     import("../components/generalDataProtection")
// );
const ContactFormPagePage = React.lazy(() => import("../Pages/Contactus"));

// const Userprofile = React.lazy(() => import("./../components/header/index"));

// const DiscriptionPage = React.lazy(() =>
//     import("./../container/landing/resources/description")
// );

const QRCodesInstructionsPage = React.lazy(() =>
  import("../pages/instructions/index.jsx")
);

const WhereToUsePage = React.lazy(() =>
  import("../pages/WhereToUse/index.jsx")
);

const ReviewsPage = React.lazy(() => import("../pages/Reviews/index.jsx"));

const HelpCenterPage = React.lazy(() =>
  import("../pages/Helpcenter/index.jsx")
);

// const TemplatePreviewPage = React.lazy(() =>
//     import("../container/templateViewer/TemplatePreviewPage")
// );

const accountPayment = React.lazy(() =>
  import("../Pages/account_&_payment/index.jsx")
);

const affiliates = React.lazy(() =>
  import("../Pages/affiliateProgram/index.jsx")
);
const BENEFITS = React.lazy(() => import("../Pages/benefits"));
const payment_success = React.lazy(() => import("../Pages/PaymentSuccess"));

// Use the HOC function to wrap all lazy-loaded pages with skeleton loader
const LazyLoginPage = withSkeletonLoader(LoginPage);
const LazySignUpPage = withSkeletonLoader(SignUpPage);
const LazyTypesOfQRPage = withSkeletonLoader(TypesOfQRPage);
const LazyQrCodeOnPage = withSkeletonLoader(QrCodeOnPage);
const LazyApiPage = withSkeletonLoader(ApiPage);
const LazyDynamicQRPage = withSkeletonLoader(DynamicQRPage);
const LazyStaticQRCodesPage = withSkeletonLoader(StaticQRCodesPage);
const LazyDataProtectionPage = withSkeletonLoader(DataProtectionPage);
const LazyBatchCreationAndDownloadPage = withSkeletonLoader(BatchCreationAndDownloadPage);
const LazyMultipleDownloadFormatsPage = withSkeletonLoader(MultipleDownloadFormatsPage);
const LazyCustomDomainPage = withSkeletonLoader(CustomDomainPage);
const LazyPlansPage = withSkeletonLoader(PlansPage);
const LazyFAQPage = withSkeletonLoader(FAQPage);
const LazyQRDescriptionPage = withSkeletonLoader(QRDescriptionPage);
const LazyPrivacyPolicy = withSkeletonLoader(privacyPolicy);
const LazyTermsOfUse = withSkeletonLoader(TermsOfUse);
const LazyForgotPasswordPage = withSkeletonLoader(ForgotPasswordPage);
const LazyBlogDetails = withSkeletonLoader(blogdetails);
const LazyResetPasswordPage = withSkeletonLoader(ResetPasswordPage);
const LazyPreview = withSkeletonLoader(preview);
const LazyAboutUs = withSkeletonLoader(about_us);
const LazyBlogPage = withSkeletonLoader(BlogPage);
const LazyQRCodesForPage = withSkeletonLoader(QRCodesForPage);
const LazyDynamicQRCodesPage = withSkeletonLoader(DynamicQRCodesPage);
const LazyContactFormPagePage = withSkeletonLoader(ContactFormPagePage);
const LazyWhereToUsePage = withSkeletonLoader(WhereToUsePage);
const LazyReviewsPage = withSkeletonLoader(ReviewsPage);
const LazyHelpCenterPage = withSkeletonLoader(HelpCenterPage);
const LazyAccountPayment = withSkeletonLoader(accountPayment);
const LazyAffiliates = withSkeletonLoader(affiliates);
const LazyBENEFITS = withSkeletonLoader(BENEFITS);
const LazyPaymentSuccess = withSkeletonLoader(payment_success);
// const LazyInstructions = withSkeletonLoader(QRCodesInstructionsPage);

export const UNAUTHENTICATED_ROUTES = [
  // { path: "/", exact: true, name: "Home" },
  {
    path: unAuthenticatedRoutesConstant.INDEX,
    exact: true,
    name: "Home",
    component: IndexPage,
  },
  {
    path: unAuthenticatedRoutesConstant.WHERE_TO_USE,
    exact: true,
    name: "WhereToUse",
    component: WhereToUsePage,
  },
  {
    path: unAuthenticatedRoutesConstant.REVIEWS,
    exact: true,
    name: "Reviews",
    component: ReviewsPage,
  },
  {
    path: unAuthenticatedRoutesConstant.HELP_CENTER,
    exact: true,
    name: "HelpCenter",
    component: HelpCenterPage,
  },
  {
    path: unAuthenticatedRoutesConstant.Privacy_Policy,
    exact: true,
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: unAuthenticatedRoutesConstant.TYPES_OF_QR,
    exact: true,
    name: "TypesofQr",
    component: TypesOfQRPage,
  },
  {
    path: unAuthenticatedRoutesConstant.QR_CODES_ON,
    exact: true,
    name: "qrCodeOn",
    component: QrCodeOnPage,
  },
  {
    path: unAuthenticatedRoutesConstant.DYNAMIC_QR,
    exact: true,
    name: "DynamicQr",
    component: DynamicQRPage,
  },
  {
    path: unAuthenticatedRoutesConstant.STATIC_QR_CODES,
    exact: true,
    name: "StaticQr",
    component: StaticQRCodesPage,
  },
  {
    path: unAuthenticatedRoutesConstant.GENERAL_DATA_PROTECTION,
    exact: true,
    name: "dataProtection",
    component: DataProtectionPage,
  },
  {
    path: unAuthenticatedRoutesConstant.BATCH_CREATION_AND_DOWNLOAD,
    exact: true,
    name: "BatchCreationAndDownload",
    component: BatchCreationAndDownloadPage,
  },
  {
    path: unAuthenticatedRoutesConstant.MULTIPLE_CDOWNALOD_FORMATS,
    exact: true,
    name: "MultipleDownloadFormats",
    component: MultipleDownloadFormatsPage,
  },
  {
    path: unAuthenticatedRoutesConstant.CUSTOM_DOMAIN,
    exact: true,
    name: "CustomDomain",
    component: CustomDomainPage,
  },
  {
    path: unAuthenticatedRoutesConstant.PLANS_AND_PRICES,
    exact: true,
    name: "plans-and-pricing",
    component: PlansPage,
  },
  {
    path: unAuthenticatedRoutesConstant.FAQ,
    exact: true,
    name: "Faq",
    component: FAQPage,
  },
  {
    path: unAuthenticatedRoutesConstant.TERMS_OF_USE,
    exact: true,
    name: "TermsOfUse",
    component: TermsOfUse,
  },

  {
    path: unAuthenticatedRoutesConstant.LOGIN,
    exact: true,
    name: "Login",
    component: LoginPage,
  },
  {
    path: unAuthenticatedRoutesConstant.LOGIN,
    exact: true,
    name: "Login",
    component: IndexPage,
  },
  {
    path: unAuthenticatedRoutesConstant.SIGNUP,
    exact: true,
    name: "SignUp",
    component: SignUpPage,
  },
  {
    path: unAuthenticatedRoutesConstant.DASHBOARD,
    exact: true,
    name: "Dashboard",
    component: DashboardPage,
  },
  {
    path: unAuthenticatedRoutesConstant.QR_TEMPLATE,
    exact: true,
    name: "qrTemplate",
    component: QRTemplatePage,
  },
  {
    path: unAuthenticatedRoutesConstant.MY_DOMAIN,
    exact: true,
    name: "my-domain",
    component: DomainPage,
  },
  {
    path: unAuthenticatedRoutesConstant.API,
    exact: true,
    name: "api",
    component: ApiPage,
  },
  {
    path: unAuthenticatedRoutesConstant.MenuTesting,
    exact: true,
    name: "api",
    // component: TestingMenuPage,
  },
  {
    path: unAuthenticatedRoutesConstant.FORGOT_PASSWORD,
    exact: true,
    name: "ForgotPassword",
    component: ForgotPasswordPage,
  },
  {
    path: unAuthenticatedRoutesConstant.RESET_PASSWORD,
    exact: true,
    name: "ResetPassword",
    component: ResetPasswordPage,
  },
  {
    path: unAuthenticatedRoutesConstant.about_us,
    exact: true,
    name: "about us",
  },
  {
    path: unAuthenticatedRoutesConstant.Aboutus,
    exact: true,
    name: "ResetPassword",
    component: about_us,
  },
  {
    path: unAuthenticatedRoutesConstant.PREVIEW,
    exact: true,
    name: "PreviewQR",
    component: preview,
  },
  // {
  //     path: unAuthenticatedRoutesConstant.SIGNUP,
  //     exact: true,
  //     name: "SignUp",
  //     component: SignUpPage,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.FORGOT_PASSWORD,
  //     exact: true,
  //     name: "ForgotPassword",
  //     component: ForgotPasswordPage,
  // },
  {
    path: unAuthenticatedRoutesConstant.Account_Payment,
    exact: true,
    name: "SignUp",
    component: accountPayment,
  },
  {
    path: unAuthenticatedRoutesConstant.Affiliate,
    exact: true,
    name: "ForgotPassword",
    component: affiliates,
  },
  // {
  //     path: unAuthenticatedRoutesConstant.RESET_PASSWORD,
  //     exact: true,
  //     name: "ResetPassword",
  //     component: ResetPasswordPage,
  // },
  {
    path: unAuthenticatedRoutesConstant.PLANS_AND_PRICES,
    exact: true,
    name: "PlansAndPrice",
    component: PlansAndPricePage,
  },
  // {
  //     path: unAuthenticatedRoutesConstant.FAQ,
  //     exact: true,
  //     name: "Faq",
  //     component: FaqPage,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.API,
  //     exact: true,
  //     name: "API",
  //     component: ApiPage,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.TYPE_OF_QR,
  //     exact: true,
  //     name: "TypeOfQR",
  //     component: TypeOfQRPage,
  // },
  {
    path: unAuthenticatedRoutesConstant.QR_CODES_FOR,
    exact: true,
    name: "QRCodesFor",
    component: QRCodesForPage,
  },
  // {
  //     path: unAuthenticatedRoutesConstant.QR_CODES_ON,
  //     exact: true,
  //     name: "QRCodesOn",
  //     component: QRCodesOnPage,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.STATIC_QR_CODES,
  //     exact: true,
  //     name: "StaticQRCodes",
  //     component: StaticQRCodesPage,
  // },
  {
    path: unAuthenticatedRoutesConstant.DYNAMIC_QR_CODES,
    exact: true,
    name: "DynamicQRCodes",
    component: DynamicQRCodesPage,
  },

  {
    path: unAuthenticatedRoutesConstant.CONTACT_FORM,
    exact: true,
    name: "ContactForm",
    component: ContactFormPagePage,
  },
  // {
  //     path: unAuthenticatedRoutesConstant.DESCRIPTION,
  //     exact: true,
  //     name: "Discription",
  //     component: DiscriptionPage,
  // },
  {
    path: unAuthenticatedRoutesConstant.INSTRUCTIONS,
    exact: true,
    name: "Instructions",
    component: QRCodesInstructionsPage,
  },
  {
    path: unAuthenticatedRoutesConstant.QR_CODES_DESCRIPTION,
    exact: true,
    name: "QRDescription",
    component: QRDescriptionPage,
  },
  {
    path: unAuthenticatedRoutesConstant.BLOG,
    exact: true,
    name: "BlogPage",
    component: BlogPage,
  },
  {
    path: unAuthenticatedRoutesConstant.BlogDetail,
    exact: true,
    name: "blogdetails",
    component: blogdetails,
  },
  // {
  //     path: unAuthenticatedRoutesConstant.PREVIEW_REDIRECT,
  //     exact: true,
  //     name: "PreviewRedirect",
  //     component: TemplatePreviewPageRedirect,
  // },
];

// AuthenticatedRoutes
export const authenticatedRoutesConstant = {
  MY_QR_CODES: "/my-qr-codes",
  FOLDER_ID: "/my-qr-codes/folder/:id",
  SCAN_COUNT: "/:id",
  // TEMPLATE: "/template",
  // CREATE_TEMPLATE: "/template/new",
  // EDIT_TEMPLATE: "/template/edit/:id",
  // NEW_QR: "/new-qr",
  // EDIT_QR: "/qr/:id",
  // TEST_TEMPLATES: "/test-templates",
  // MY_DOMAINS: "/my-domains",
  STATS: "/stats",
  NEW_QR: "/new-qr",
  QR_DESIGN: "/qr_design",
  DASHBOARD: "/dashboard",
  MY_QR_CODES: "/my-qr-codes",
  MenuTesting: "/dashboard/:id",
  Error404: "/error404",
  Qr_details: "/qrdetails",

  // STATS_BY_ID: "/stats/:id",
  // PLANS_AND_PAYMENTS: "/plans",
  // PLANS_AND_PAYMENTS_CHECKOUT: "/plans/checkout",
  SETTINGS: "/settings",
  QRDESCRIPTION: "/qr-description",
  BULK_QR: "/bulkqrgeneration",
  Users: "/users",
  PREVIEW: "/p/:id",
  // PREVIEW_REDIRECT: "/:id",
  // LOGIN: "/login",
  // DASHBOARD: "/dashboard",
  // MY_QR_CODES: "/my-qr-codes",
  // TEMPLATE: "/template",
  // CREATE_TEMPLATE: "/template/new",
  // EDIT_TEMPLATE: "/template/edit/:id",
  // NEW_QR: "/new-qr",
  // EDIT_QR: "/qr/:id",
  // TEST_TEMPLATES: "/test-templates",
  // MY_DOMAINS: "/my-domains",
  // STATS_BY_ID: "/stats/:id",
  PLANS_AND_PAYMENTS: "/plans",
  PLANS_AND_PAYMENTS_CHECKOUT: "/plans/checkout",
  QR_DESIGN: "/qr-design/:id",
  BULK_QR_GEN: "/bulkqrgeneration/qr-design/:id",
  UploadCSV: "bulkqrgeneration/upload-csv",
  BENEFITS: "/benefits",
  HELP_CENTER: "/dashboard/help-center",
  QR_TEMPLATE: "/template",
  payment_success: "/payment-success",

  // SETTINGS: "/settings",
  // PREVIEW: "/p/:id",
  // PREVIEW_REDIRECT: "/:id",
  // LOGIN: "/login",
};

const error404 = React.lazy(() => import("../pages/404NotFound/index.jsx"));
// const MyQRCodePage = React.lazy(() =>
//     import("./../container/dashboard/myQRCodes/index")
// );
// const TemplatePage = React.lazy(() =>
//     import("./../container/dashboard/template/index")
// );
// const CreateTemplatePage = React.lazy(() =>
//     import("../container/dashboard/template/add/index")
// );
// const UpdateTemplatePage = React.lazy(() =>
//     import("../container/dashboard/template/edit/index")
// );

const MenuTestingPage = React.lazy(() =>
  import("./../Pages/newQrpage/newQrpage.jsx")
);
const UsersPage = React.lazy(() => import("../Pages/users"));
const QRDesigner = React.lazy(() => import("../Pages/qrDesign"));
const BULK_QR_DESIGN = React.lazy(() => import("../Pages/qrDesign"));
const UploadCSV = React.lazy(() => import("../Pages/csv"));
const qrdetails = React.lazy(() => import("./../Pages/qrdetails/index.jsx"));
// const TestingMenuPage = React.lazy(() => import("../pages/New_Qr/index.jsx"));
const StatsPage = React.lazy(() => import("../Pages/stats/"));
const PlansAndPaymentPage = React.lazy(() =>
  import("../pages/plans/index.jsx")
);
const PlansAndPaymentCheckoutPage = React.lazy(() =>
  import("../pages/planscheckout/index.jsx")
);
const BULK_QR_GEN = React.lazy(() => import("../Pages/bulkqr/index.jsx"));
const MY_QR_FOLDER = React.lazy(() => import("../Pages/QrFolder/index.jsx"));

// const PlansAndPaymentPage = React.lazy(() =>
//     import("./../container/dashboard/plansAndPayments/index")
// );
// const PlansAndPaymentCheckoutPage = React.lazy(() =>
//     import("./../container/dashboard/plansAndPayments/checkout/index")
// );
const setting = React.lazy(() => import("../Pages/setting"));
// const BENEFITS = React.lazy(() => import("../Pages/benefits"));

// const QR_DESIGN = React.lazy(() => import("../Pages/qrDesign"));

// // eslint-disable-next-line react-refresh/only-export-components
// const TestTemplatesPage = React.lazy(() =>
//     // import("./../container/dashboard/template/index")
//     import("./../container/dashboard/newQR/TestTemplates")
// );
// const SettingsPage = React.lazy(() => import("../Pages/setting"));

export const AUTHENTICATED_ROUTES = [
  {
    path: authenticatedRoutesConstant.SETTINGS,
    exact: true,
    name: "Setting",
    component: setting,
  },
  {
    path: authenticatedRoutesConstant.QR_DESIGN,
    exact: true,
    name: "QrDesign",
    component: QRDesigner,
  },
  {
    path: authenticatedRoutesConstant.BULK_QR_GEN,
    exact: true,
    name: "BulkQrDesign",
    component: QRDesigner,
  },
  {
    path: authenticatedRoutesConstant.MenuTesting,
    exact: true,
    name: "MenuTesting",
    component: MenuTestingPage,
  },
  {
    path: authenticatedRoutesConstant.BULK_QR,
    exact: true,
    name: "bulkqrgeneration",
    component: BULK_QR_GEN,
  },
  {
    path: authenticatedRoutesConstant.payment_success,
    exact: true,
    name: "PaymentSuccess",
    component: LazyPaymentSuccess,
  },
  {
    path: authenticatedRoutesConstant.UploadCSV,
    exact: true,
    name: "UploadCSV",
    component: UploadCSV,
  },
  {
    path: authenticatedRoutesConstant.Error404,
    exact: true,
    name: "error404",
    component: error404,
  },
  {
    path: authenticatedRoutesConstant.BENEFITS,
    exact: true,
    name: "BENEFITS",
    component: BENEFITS,
  },
  {
    path: authenticatedRoutesConstant.HELP_CENTER,
    exact: true,
    name: "HelpCenter",
    component: HelpCenterPage,
  },
  {
    path: authenticatedRoutesConstant.Qr_details,
    exact: true,
    name: "QRDesign",
    component: qrdetails,
  },
  {
    path: authenticatedRoutesConstant.Users,
    exact: true,
    name: "users",
    component: UsersPage,
  },
  {
    path: authenticatedRoutesConstant.FOLDER_ID,
    exact: true,
    name: "my-qr-folder",
    component: MY_QR_FOLDER,
  },
  {
    path: authenticatedRoutesConstant.SCAN_COUNT,
    exact: true,
    name: "scan-count",
    component: ScanCountPage,
  },
  // {
  //     path: authenticatedRoutesConstant.MY_QR_CODES,
  //     exact: true,
  //     name: "MyQRCode",
  //     component: MyQRCodePage,
  // },
  // {
  //     path: authenticatedRoutesConstant.TEMPLATE,
  //     exact: true,
  //     name: "Template",
  //     component: TemplatePage,
  // },
  // {
  //     path: authenticatedRoutesConstant.CREATE_TEMPLATE,
  //     exact: true,
  //     name: "Create Template",
  //     component: CreateTemplatePage,
  // },
  // {
  //     path: authenticatedRoutesConstant.EDIT_TEMPLATE,
  //     exact: true,
  //     name: "Edit Template",
  //     component: UpdateTemplatePage,
  // },
  // {
  //   path: authenticatedRoutesConstant.NEW_QR,
  //   exact: true,
  //   name: "NewQR",
  //   component: NewQRPage,
  // },
  // {
  //     path: authenticatedRoutesConstant.EDIT_QR,
  //     exact: true,
  //     name: "EDIT_QR",
  //     component: NewQRPage,
  // },
  // {
  //     path: authenticatedRoutesConstant.TEST_TEMPLATES,
  //     exact: true,
  //     name: "TestTemplates",
  //     component: TestTemplatesPage,
  // },
  // {
  //     path: authenticatedRoutesConstant.MY_DOMAINS,
  //     exact: true,
  //     name: "MyDomains",
  //     component: MyDomainsPage,
  // },
  {
    path: unAuthenticatedRoutesConstant.QR_TEMPLATE,
    exact: true,
    name: "qrTemplate",
    component: QRTemplatePage,
  },
  {
    path: unAuthenticatedRoutesConstant.MY_DOMAIN,
    exact: true,
    name: "my-domain",
    component: DomainPage,
  },
  {
    path: authenticatedRoutesConstant.STATS,
    exact: true,
    name: "Stats",
    component: StatsPage,
  },
  {
    path: authenticatedRoutesConstant.PREVIEW,
    exact: true,
    name: "Stats",
    component: StatsPage,
  },
  {
    path: authenticatedRoutesConstant.PLANS_AND_PAYMENTS,
    exact: true,
    name: "PlansAndPayment",
    component: PlansAndPaymentPage,
  },
  {
    path: authenticatedRoutesConstant.PLANS_AND_PAYMENTS_CHECKOUT,
    exact: true,
    name: "PlansAndPaymentCheckout",
    component: PlansAndPaymentCheckoutPage,
  },
  // {
  //   path: authenticatedRoutesConstant.NEW_QR,
  //   exact: true,
  //   name: "NewQr",
  //   component: NewQRPage,
  // },
  {
    path: authenticatedRoutesConstant.DASHBOARD,
    exact: true,
    name: "Dashbaord",
    component: DashboardPage,
  },
  {
    path: authenticatedRoutesConstant.MY_QR_CODES,
    exact: true,
    name: "MyQr",
    component: MY_QR_CODES,
  },

  // {
  //     path: authenticatedRoutesConstant.PLANS_AND_PAYMENTS,
  //     exact: true,
  //     name: "PlansAndPayment",
  //     component: PlansAndPaymentPage,
  // },
  // {
  //     path: authenticatedRoutesConstant.PLANS_AND_PAYMENTS_CHECKOUT,
  //     exact: true,
  //     name: "PlansAndPaymentCheckout",
  //     component: PlansAndPaymentCheckoutPage,
  // },
  // {
  //     path: authenticatedRoutesConstant.SETTINGS,
  //     exact: true,
  //     name: "Settings",
  //     component: SettingsPage,
  // },
  // {
  //     path: authenticatedRoutesConstant.USER_PROFILE,
  //     exact: true,
  //     name: "userProfile",
  //     component: Userprofile,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.PREVIEW,
  //     exact: true,
  //     name: "PreviewAuth",
  //     component: TemplatePreviewPage,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.PREVIEW_REDIRECT,
  //     exact: true,
  //     name: "PreviewRedirectAuth",
  //     component: TemplatePreviewPageRedirect,
  // },
  // {
  //     path: unAuthenticatedRoutesConstant.LOGIN,
  //     exact: true,
  //     name: "Login",
  //     component: DashboardPage,
  // },
];
