import React from 'react';
import "./loader.animation.css"
import { useSelector } from 'react-redux';

export default function LoadingComponent() {
    const message = useSelector(state => state.LOADER_SLICE?.message)

    return (
        <div className="w-screen h-screen fixed top-0 left-0 bg-[rgba(0,0,0,0.8)] flex flex-col justify-center items-center gap-5 !z-[50]">
            <div class="loader">
                <div class="box1"></div>
                <div class="box2"></div>
                <div class="box3"></div>
            </div>
            <span className='text-[#fff] text-[14px] tracking-widest'>{message}</span>
        </div >
    )
}
