import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";
import { jwtVerify } from "jose";
import { authApi } from "../../services/auth";
import {
  calculateTrialRemainingTime,
  setValueInSubscriptionSlice,
} from "./subscriptionSlice";

const userSlice = createSlice({
  name: STORE.SLICES.USER_DATA_SLICE,
  initialState: {
    user: {},
    isLoading: false,
    isError: false,
    error: null,
    ipAddress: "",
    location: {},
    hasBuyAnySubscription: false,
    paymentInstance: null,
    isAuthenticated: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setValuesOfUserData: (state, action) => {
      const nameOfState = action.payload?.name;
      const value = action.payload?.data;
      state[nameOfState] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log("Thunk Fullfilled", action.payload);
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log("Extra Reducer", action.error);

        state.error = action.error.message;
      });
  },
});

export const { setUserData, setLocation, setValuesOfUserData } =
  userSlice.actions;
const decodeToken = async (token) => {
  const secret = new TextEncoder().encode(import.meta.env.VITE_APP_JWT_SECRET);
  const { payload: decoded } = await jwtVerify(token, secret);
  return decoded;
};
// Check Authentication , Subscription and Free Trial Status
export const authenticateUser = createAsyncThunk(
  "user/fetchUserDetails",
  async (_, { dispatch, rejectWithValue }) => {
    console.log("Thunk of Authentication Called ");
    const user = localStorage.getItem("user");
    if (!user) {
      return rejectWithValue("User not found");
    }

    const { accessToken } = JSON.parse(user);
    if (!accessToken) {
      return rejectWithValue("No access token found");
    }

    const decodedData = await decodeToken(accessToken);
    try {
      const response = await dispatch(
        authApi.endpoints.getUserDetails.initiate(decodedData.id)
      ).unwrap();
      dispatch(
        setValuesOfUserData({
          name: "paymentInstance",
          data: {
            pm_last_four: response?.pm_last_four,
            pm_type: response?.pm_type,
          },
        })
      );
      if (response?.subscriptionStatus !== "trial") {
        dispatch(
          setValuesOfUserData({ name: "hasBuyAnySubscription", data: true })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "type",
            data: "premium",
          })
        );
      }
      // Setting Trial Status
      if (response?.subscriptionStatus === "trial") {
        dispatch(
          setValueInSubscriptionSlice({
            name: "type",
            data: response?.subscriptionStatus,
          })
        );
        const trialEndDate = new Date(response?.trial_ends_at);
        const currentDate = Date.now();
        const remainingTime = trialEndDate - currentDate;
        dispatch(
          setValueInSubscriptionSlice({
            name: "trial_remaining_time",
            data: remainingTime,
          })
        );
        dispatch(calculateTrialRemainingTime());
        dispatch(
          setValuesOfUserData({ name: "hasBuyAnySubscription", data: false })
        );
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch user details");
    }
  }
);
export const fetchUserIp = () => async (dispatch) => {
  try {
    const response = await fetch(`https://api64.ipify.org`);
    const ip = await response.text();
    dispatch(
      setValuesOfUserData({
        name: "ipAddress",
        data: ip,
      })
    );
  } catch (error) {
    console.log("Error fetching IP address:", error);
  }
};
export default userSlice.reducer;
