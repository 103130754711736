import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";
import { plansApi } from "../../services/plans";
import { setValuesOfUserData } from "./userSlice";
import { setValueInLoaderState } from "./loaderSlice";
import { toastSuccess } from "../../utils/toastUtils";

const subscriptionSlice = createSlice({
  name: STORE.SLICES.SUBSCRIPTION_SLICE,
  initialState: {
    allSubscriptions: null,
    isFreeTrialValid: false,
    createdAt: null,
    type: null,
    trial_ends_at: null,
    trial_remaining_time: null,
  },
  reducers: {
    setValueInSubscriptionSlice: (state, action) => {
      const nameOfState = action.payload?.name;
      const value = action.payload?.data;
      state[nameOfState] = value;
    },
  },
});

export const { setValueInSubscriptionSlice } = subscriptionSlice.actions;

export const getUserCurrentSubscription = () => async (dispatch, getState) => {
  const state = getState();
  const userId = state?.root?.USER_DATA_SLICE?.user?.id;
  try {
    const response = await dispatch(
      plansApi.endpoints.getSubscriptions.initiate(userId)
    ).unwrap();
    if (response) {
      if (response?.length > 0) {
        console.log("Last Subsscription==>", response[0]);
        dispatch(
          setValueInSubscriptionSlice({
            name: "allSubscriptions",
            data: response,
          })
        );
        dispatch(
          setValuesOfUserData({ name: "hasBuyAnySubscription", data: true })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "currentSubscriptionDetails",
            data: response[0],
          })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "currentSubscriptionId",
            data: response[0]?.stripe_id,
          })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "currentSubscriptionStatus",
            data: response[0]?.stripe_status,
          })
        );
      } else {
        dispatch(
          setValueInSubscriptionSlice({ name: "allSubscriptions", data: [] })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "currentSubscriptionDetails",
            data: null,
          })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "currentSubscriptionId",
            data: null,
          })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "currentSubscriptionStatus",
            data: null,
          })
        );
        dispatch(
          setValuesOfUserData({ name: "hasBuyAnySubscription", data: false })
        );
      }
    }
  } catch (error) {
    console.log("SUBSCRIPTION_ERROR==>", error);
  }
};

export const calculateTrialRemainingTime = () => (dispatch, getState) => {
  const state = getState();
  const remainingTime = state?.root?.SUBSCRIPTION_SLICE?.trial_remaining_time;
  let trialInterval;
  if (remainingTime > 0) {
    trialInterval = setInterval(() => {
      const currentState = getState();
      const current_remaining_time =
        currentState?.root?.SUBSCRIPTION_SLICE?.trial_remaining_time;
      if (current_remaining_time > 0) {
        dispatch(
          setValueInSubscriptionSlice({
            name: "trial_remaining_time",
            data: current_remaining_time - 1000,
          })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "isFreeTrialValid",
            data: true,
          })
        );
      } else {
        clearInterval(trialInterval);
        trialInterval = null;
        dispatch(
          setValueInSubscriptionSlice({
            name: "trial_remaining_time",
            data: 0,
          })
        );
        dispatch(
          setValueInSubscriptionSlice({
            name: "isFreeTrialValid",
            data: false,
          })
        );
      }
    }, 1000);
  }
};

export const createSubscription =
  (paymentMethod) => async (dispatch, getState) => {
    const currentState = getState();
    const userData = currentState?.root?.USER_DATA_SLICE;
    const plansData = currentState?.root?.PLANS_SLICE;
    const invoiceData = currentState?.root?.INVOICE_SLICE;
    const checkoutData = currentState?.root?.CHECKOUT_SLICE;
    const payload = {
      priceId: checkoutData?.stripePriceId,
      planId: plansData?.selectedPlan?.id,
      userId: userData?.user?.id,
      paymentMethod: paymentMethod,
      discountId: "",
      companyName: invoiceData?.data?.companyName,
      taxId: invoiceData?.data?.tax_id,
      name: invoiceData?.data?.name,
      surname: invoiceData?.data?.surname,
      address: invoiceData?.data?.address,
      postalCode: invoiceData?.data?.postal_code,
      city: invoiceData?.data?.city,
      country: invoiceData?.data?.country,
      email: invoiceData?.data?.email,
    };

    try {
      const response = await dispatch(
        plansApi.endpoints.newSubscriptionService.initiate(payload)
      ).unwrap();
      if (response?.status == "active") {
        dispatch(
          setValuesOfUserData({ name: "hasBuyAnySubscription", data: true })
        );
        window.location.href = "/payment-success";
        toastSuccess("Subscription created successfully");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

export const updateCurrentSubscription =
  (subscriptionId) => (dispatch, getState) => {
    const currentState = getState();
    const allSubscriptions =
      currentState?.root?.SUBSCRIPTION_SLICE?.allSubscriptions;
    const data = allSubscriptions?.map((item) => {
      if (item?.stripe_id === subscriptionId) {
        return {
          ...item,
          stripe_status: "canceled",
        };
      }
      return item;
    });

    dispatch(
      setValueInSubscriptionSlice({
        name: "allSubscriptions",
        data: data,
      })
    );
  };

export default subscriptionSlice.reducer;
