import React, { useEffect } from "react";
import "../../../styles/scrollbar.styles.css";
const SelectModal = ({
  options,
  selectedOption,
  setSelectedOption = null,
  setModalState,
  top,
  bottom,
  right,
  left,
  optionKey,
  iconKey,
  onClick = "",
  height = "fit-content",
  width = "100%",
  allowSelectValue = true,
}) => {
  useEffect(() => {
    if (allowSelectValue) {
      console.log("Selected Option in Select Modal", selectedOption);
    }
  }, [selectedOption]);
  return (
    <div
      className={`bg-[var(--darkest-bg)] flex flex-col gap-1 pt-1 pb-2 px-1 w-[100%] rounded-lg overflow-y-scroll scrollbar`}
      style={{
        // position: "absolute",
        bottom: bottom ? bottom : "auto",
        top: top ? top : "auto",
        right: right ? right : "auto",
        left: left ? left : "auto",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        height: height,
        width: width,
      }}
    >
      {options.map((option, index) => {
        const displayText =
          typeof option === "object" && option !== null
            ? option[optionKey]
            : option;

        const icon =
          typeof option === "object" && option !== null && option[iconKey];
        return (
          <div
            className={` w-[100%] flex items-center gap-2 cursor-pointer py-1  px-2 text-[14px] ${
              selectedOption === option && "bg-[var(--darker-bg)] font-bold"
            } rounded-sm hover:bg-[var(--darkest-bg)] text-white`}
            key={index}
            onClick={() => {
              if (allowSelectValue && setSelectedOption) {
                setSelectedOption(displayText);
              }
              setModalState(false);
              onClick && onClick(option);
            }}
          >
            {icon && (
              <span className="mr-2">
                <img src={icon} className="w-4" />
              </span>
            )}
            {displayText}
          </div>
        );
      })}
    </div>
  );
};

export default SelectModal;
