import React, { useEffect, useState } from "react";
import {
  GoogleOAuthProvider,
  GoogleLogin as GoogleLogin2,
} from "@react-oauth/google";
import FacebookLogin from "react-facebook-login-lite";
import { toast } from "react-toastify";
import Google from "../../assets/google.webp";
import Facebook from "../../assets/facebook.webp";
import loginimage from "../../assets/loginheroimage.webp";
import logo from "../../assets/Group (3).webp";
import avate from "../../assets/Illustration.webp";
import bgimage from "../../assets/bgimage.webp";
import {
  useAuthFacebookLoginMutation,
  useAuthGoogleLoginMutation,
  useAuthLoginMutation,
} from "../../services/auth";
import { Link, useNavigate } from "react-router-dom";
import useFormValidation from "../../hooks/useFormValidation";
import { useAuth } from "../../context/authCtx";
import Turnstile from "react-turnstile";
import Button from "../../components/global/Button";
import Checkbox from "../../components/global/basic-Information/Check";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons from react-icons
import { useDispatch } from "react-redux";
import { authenticateUser } from "../../store/slices/userSlice";

const GOOGLE_CLIENT_ID =
  "566737031331-a4p8m3eve9p4pd6va75rr0oqdovivpj1.apps.googleusercontent.com";
const FACEBOOK_APP_ID = "907542704565184";

const Login = () => {
  // States
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const navigate = useNavigate();
  const { errors, validateField, validateForm } = useFormValidation();
  const { doLogin } = useAuth();
  const [CapchatToken, setCaptchaToken] = useState(true);
  const captchakey = import.meta.env.VITE_APP_CLOUD_FLARE_SITE_KEY;

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    validateField(name, value, formData);
  };

  // Normal Login Handler
  const [authLogin] = useAuthLoginMutation();
  const handleLogin = async (e) => {
    e && e.preventDefault();

    try {

      // localStorage.setItem("user", "1"); // for testing purpose
      // window.location.href = "/dashboard";

      const result = await authLogin(formData);
      if (result?.data) {
        // if (true) {
        localStorage.setItem("user", JSON.stringify(result?.data));
        // localStorage.setItem("user", "test user");
        const response = dispatch(authenticateUser(result?.data));
        console.log(response);
        doLogin(result?.data);
        navigate("/dashboard");
        toast.success("Signin Done Successfully");
      } else {
        toast.error(
          result?.error?.message || "Something went wrong, please try again"
        );
      }
    } catch (error) {
      toast.error("Something went wrong, please try again");
    }
  };

  // Google Login Handler
  const [authGoogleLogin] = useAuthGoogleLoginMutation();
  const handleGoogleLogin = async (response) => {
    if (!response.credential) {
      toast.error("Google login failed.");
      return;
    }
    try {
      const result = await authGoogleLogin({ token: response.credential });
      if (result?.data) {
        localStorage.setItem("user", JSON.stringify(result?.data));
        doLogin(result?.data);
        navigate("/dashboard");
        toast.success("Google signin successfully");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      toast.error("Something went wrong, please try again");
    }
  };

  // Facebook Login Handler
  const [authFacebookLogin] = useAuthFacebookLoginMutation();
  const handleFacebookLogin = async (response) => {
    if (!response.accessToken) {
      toast.error("Facebook login failed.");
      return;
    }
    try {
      const result = await authFacebookLogin({ token: response.accessToken });
      if (result?.data) {
        localStorage.setItem("user", JSON.stringify(result?.data));
        doLogin(result?.data);
        navigate("/dashboard");
        toast.success("Facebook signin successfull");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      toast.error("Something went wrong, please try again");
    }
  };

  // Handle Enter Key press for navigation between inputs and form submission
  const handleKeyDown = (e, nextFieldRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextFieldRef) {
        nextFieldRef.current.focus();
      } else {
        handleLogin(e); // Trigger form submission on Enter in the last input (password field)
      }
    }
  };

  const emailRef = React.useRef();
  const passwordRef = React.useRef();

  return (
    <div className="min-h-screen flex flex-col md:flex-row-reverse p-6 md:p-0 bg-[var(--dark-bg)] md:bg-transparent">
      {/* Left Section - Hero Image */}
      <div className="hidden relative lg:flex lg:w-[75%] bg-cover bg-center bg-[var(--dark-bg)]">
        <div className="absolute inset-0 w-full h-full">
          <img
            src={bgimage}
            alt=""
            className="w-full h-full object-cover opacity-50"
          />
        </div>

        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src={avate}
            alt="Login User"
            className="w-full h-[55%] md:w-3/6"
          />
        </div>
      </div>

      {/* Right Section - Signup Form */}
      <div className="w-full lg:w-[25%] bg-[var(--darker-bg)] text-white flex flex-col justify-center items-center p-6 sm:p-8">
        <div className="w-full max-w-md">
          <div className="text-center flex flex-col mb-2 md:mb-6">
            <img src={logo} alt="logo" className="mx-auto" />
            <h1 className="text-3xl font-bold">
              <span>QR</span>
              <span className="text-[var(--primary)]">GEN</span>
            </h1>
          </div>
          {/* Signup Title */}
          <h2 className="text-center text-xl font-serif font-semibold mb-4">
            login
          </h2>

          <div className="flex flex-row justify-center items-center mt-6 gap-4 w-full">
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin2
                onSuccess={handleGoogleLogin}
                onError={() => toast.error("Google login failed")}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className={`flex items-center justify-center gap-2 border rounded py-2 px-4 w-full sm:w-1/2 hover:bg-gray-800 transition duration-200`}
                    text={`Google`}
                  >
                    <img src={Google} alt="Google" className="w-6" />
                  </Button>
                )}
              />
            </GoogleOAuthProvider>
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={handleFacebookLogin}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={`flex items-center justify-center gap-2  rounded py-3 px-4 w-full sm:w-1/2 hover:bg-gray-800 bg-[var(--dark-bg)] transition duration-200`}
                  text={`Facebook`}
                >
                  <img src={Facebook} alt="Facebook" className="w-6" />
                </Button>
              )}
            />
          </div>

          {/* Divider */}
          <div className="flex items-center justify-between my-4">
            <hr className="w-full border-gray-500" />
            <span className="text-white mx-2">Or</span>
            <hr className="w-full border-gray-500" />
          </div>

          {/* Form Inputs */}
          {/* Email Input */}
          <div className="mt-4">
            <label
              onClick={() => console.log(captchakey)}
              className="block mb-1 text-sm font-medium"
            >
              Email
            </label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, passwordRef)} // Focus on password input when Enter is pressed
              ref={emailRef}
              className={`border text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-[var(--dark-bg)] placeholder-gray-400 ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="name@example.com"
              required
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          {/* Password Input */}
          <div className="mt-4">
            <label className="block mb-1 text-sm font-medium">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} // Change input type based on state
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                onKeyDown={(e) => handleKeyDown(e)} // Trigger login on Enter press
                ref={passwordRef}
                className={`border text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 bg-[var(--dark-bg)] placeholder-gray-400 ${
                  errors.password ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="********"
                required
              />
              {/* Eye icon button */}
              <button
                type="button"
                onClick={togglePassword}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              >
                {!showPassword ? (
                  <FaEyeSlash className="w-5 h-5 text-white-500" /> // Eye slash icon when password is visible
                ) : (
                  <FaEye className="w-5 h-5 text-white-500" /> // Eye icon when password is hidden
                )}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          {/* Remember Me and Forgot Password */}
          <div className="flex justify-between mt-4">
            <div className="flex items-center">
              {/* Cloudflare Turnstile CAPTCHA */}
              <Checkbox
                checked={formData.remember}
                onChange={(checked) =>
                  handleInputChange({
                    target: { name: "remember", type: "checkbox", checked },
                  })
                }
                label="Remember Me"
              />
            </div>
            <Link
              to={"/forgot-password"}
              className="text-sm font-medium text-[var(--primary)] hover:underline"
            >
              Forgot password?
            </Link>
          </div>
          <div className="text-center mt-4">
          
          </div>
          {/* Sign In Button */}
          <Button
            className={`w-full mt-4 bg-[var(--primary)] text-black p-2 rounded hover:bg-cyan-500 transition duration-200`}
            onClick={handleLogin}
            text={`Sign In`}
          />

          <p className="text-center mt-4 text-sm text-white">
            Don’t have an account yet?{" "}
            <Link
              to={"/signup"}
              className="text-[var(--primary)] font-semibold"
            >
              New Account
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
