import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const domainSlice = createSlice({
  name: "domains",
  reducerPath: STORE.SLICES.DOMAIN_SLICE,
  initialState: {
    domainsData: [],
    selectedDomains: {},
  },
  reducers: {
    setDomains: (state, action) => {
      state.domainsData = action.payload;
    },
    setSelectedDomains: (state, action) => {
      state.selectedDomains = action.payload;
    },
  },
});

export const { setDomains, setSelectedDomains } = domainSlice.actions;
export const addDomain = (payload) => async (dispatch, getState) => {
  console.log("payload ===>", payload);
  const state = getState();
  const { domainsData } = state.root.DOMAIN_SLICE;
  const data = [...domainsData, payload];
  console.log("Total domain data ===>", data);

  dispatch(setDomains(data));
};

export const removeDomain = (domainID) => async (dispatch, getState) => {
  const state = getState();
  const { domainsData } = state.root.DOMAIN_SLICE;
  const data = domainsData.filter((item) => item.id !== domainID);
  console.log("Total domain data ===>", data);
  dispatch(setDomains(data));
};

export const updateDomainData =
  (domainID, payload) => async (dispatch, getState) => {
    const state = getState();
    const { domainsData } = state.root.DOMAIN_SLICE;
    const data = domainsData.map((item) => {
      if (item.id === domainID) {
        return { ...item, ...payload };
      }
      return item;
    });
    dispatch(setDomains(data));
  };

export default domainSlice.reducer;
