import { createSlice } from "@reduxjs/toolkit";
import STORE from "../../constants/STORE";

const loaderSlice = createSlice({
  name: STORE.SLICES.LOADER_SLICE,
  initialState: {
    loading: false,
    message: "",
  },
  reducers: {
    setValueInLoaderState: (state, action) => {
      const name = action.payload?.name;
      const value = action.payload?.data;
      state[name] = value;
    },
  },
});

export const { setValueInLoaderState } = loaderSlice.actions;
export default loaderSlice.reducer;
