import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft, ArrowRight, X } from "lucide-react";
import { Link } from "react-router-dom";
import Qrlogo from "../../assets/QR24Logo.webp";
import sidebarData from "./SidebarData/SidebarData.jsx";
import SidebarProfile from "../SidebarProfile.jsx";
import userPicture from "../../assets/Profile.webp";
import unAuthSidebar from "./SidebarData/unAuthSidebar.jsx";

const profiledata = {
  // name: "Easin Arafat",
  image: userPicture,
  accountType: "Free Account",
};
const MobileSidebar = ({ menuState, stateMenuState, sidebarMenuData = sidebarData, isLoggedIn = true, className, path }) => {
  const isSideBarOpen = localStorage.getItem("isSidebarOpen");
  const [activeLink, setActiveLink] = useState(() => {
    const storedLink = localStorage.getItem("mobileActiveLink");

    // return storedLink ? JSON.parse(storedLink) : "/dashboard";
  });
  
  const [openIndex, setOpenIndex] = useState(null);
  const toggleSubmenu = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // const [isSideBarOpen, setisSideBarOpen] = useState(true)
  const sidebarRef = useRef(null);
  // const path = location.pathname
  const [isData ,setData]= useState()
  // console.log(location.pathname);
  useEffect(() => {
    localStorage.setItem("mobileActiveLink", JSON.stringify(activeLink));
  }, [activeLink]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        stateMenuState(false);
      }
    };
      if(path == "/dashboard"){
        setData(sidebarData)
      }else{
        setData(unAuthSidebar)
      }

    console.log(isData)
    // Add event listener only if sidebar is open
    if (menuState) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuState, stateMenuState]);

  return (
    <div className={`fixed overflow-y-auto inset-0 bg-[var(--dark-bg)] ${className}`}>
      <div className="w-[300px] bg-[var(--dark-bg)] h-full transition-all duration-300 ">
        <div
          className="h-full flex flex-col justify-between py-1 shadow-md"
          ref={sidebarRef}
        >
          {/* Header Section */}
          <div>
            <div className="flex flex-col items-center px-2  mb-1 relative">
              <button
                className="cursor-pointer p-2 absolute right-4 top-4 rounded-md"
                onClick={() => stateMenuState(!menuState)}
              >
                <X size={20} className="text-[var(--primary)]" />
              </button>

              <div className="h-24 mt-5 flex justify-center items-center">
                <img
                  src={Qrlogo}
                  alt="QR Logo"
                  className="transition-all duration-300"
                />
              </div>
            </div>

            {/* Navigation Links */}
     
            <ul className="flex flex-col gap-3 w-[100%]">
            {sidebarMenuData.map((item, index) => (
              <div key={index} className="relative">
                
                {item.children ? (
                  <div
                    className="relative flex items-center py-3 text-[var(--text-light)] px-6 cursor-pointer whitespace-nowrap"
                    onClick={() => toggleSubmenu(index)}
                  >
                    <p className="ml-2 text-[16px] font-semibold whitespace-nowrap relative zflex -10">
                      {item.title} 
                      <span className="ml-2">{ (openIndex === index) ? "▲" : "▼"  } </span>
                    </p>
                  </div>
                ) : (
                  <Link
                    to={item.route}
                    className={`relative flex items-center py-3 text-[var(--text-light)] px-6 cursor-pointer whitespace-nowrap transition-all duration-200 hover:text-[var(--primary)] ${item.route === activeLink ? "bg-[var(--darker-bg)]" : ""}`}
                    onClick={() => {
                      setActiveLink(item.route);
                      setTimeout(() => { stateMenuState(false) }, 100);
                      // Close sidebar after selection on mobile
                    }}
                  >
                    <p className="ml-2 text-[16px] font-semibold whitespace-nowrap relative z-10">
                      {item.title}
                    </p>
                  </Link>
                )}
                {item.children && openIndex === index && (
                  <ul className="shadow-md rounded-md w-40 mt-2 3 w-[100%] bg-[var(--darker-bg)]">
                    {item.children.map((child, idx) => (
                      <li key={idx}>
                        <Link
                          to={child.route}
                          className={`block px-8 py-2 text-white hover:text-[var(--primary)] ${item.route === activeLink ? "" : ""}`}
                          onClick={() => {
                            setActiveLink(item.route);
                            setTimeout(() => { stateMenuState(false) }, 100);
                            // Close sidebar after selection on mobile
                          }}
                        >
                          {child.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </ul>

          </div>
          { isLoggedIn &&  <SidebarProfile
            profileData={profiledata}
            isSideBarOpen={isSideBarOpen}
          /> }
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
