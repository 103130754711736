import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  useTransition,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth } from "./context/authCtx";
import {
  useAuthLogoutMutation,
  useRefreshTokenMutation,
} from "./services/auth";
import { jwtVerify } from "jose";
import { AnimatePresence, motion } from "framer-motion";
import ProtectedRoute from "./constants/ProtectedRoute";
import UnauthenticatedRoute from "./constants/UnauthenticatedRoute";
import AuthenticatedRoute from "./constants/AuthenticatedRoute";
import DashboardPage from "./components/shared/dashboard";
import Login from "./Pages/login";
import { TransitionProvider } from "./context/Transitioncontext";
import {
  UNAUTHENTICATED_ROUTES,
  AUTHENTICATED_ROUTES,
} from "./constants/ROUTES";
import SkeletonLoader from "./components/global/skeleton/SkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser, fetchUserIp } from "./store/slices/userSlice";
// import SkeletonLoader from "./components/global/skeleton/SkeletonLoader";
// import { Skeletoncontext } from "./context/Skeleton";
import { Skeletoncontext } from "./context/Skeleton";
import LoadingComponent from "./components/shared/others/LoadingComponent";
import { fetchPlansData } from "./store/slices/plansSlice";
// import SkeletonLoader from "./components/global/skeleton/SkeletonLoader";
// import { Skeletoncontext } from "./context/Skeleton";

function App() {
  const { isAuthenticated, doLogout } = useAuth();
  const [refreshToken] = useRefreshTokenMutation();
  const [authLogout] = useAuthLogoutMutation();
  const [isVisible, setIsVisible] = useState(false);
  const [isPending, startTransition] = useTransition();
  // const { skeleton, setskeleton } = useContext(Skeletoncontext);
  const dispatch = useDispatch();
  const { loading: loaderLoading, message: loaderMessage } = useSelector(
    (state) => state.LOADER_SLICE
  );
  const ipAddress = useSelector(
    (state) => state.root.USER_DATA_SLICE?.ipAddress
  );

  const checkAndRefreshToken = async () => {
    const { accessToken } = JSON.parse(localStorage.getItem("user"));
    if (!accessToken) return;

    const secret = new TextEncoder().encode(
      import.meta.env.VITE_APP_JWT_SECRET
    );
    const { payload: decoded } = await jwtVerify(accessToken, secret);

    const currentTime = Math.floor(Date.now() / 1000);
    if (!(decoded?.exp && decoded?.exp - currentTime < 10 * 60)) {
      const response = await refreshToken({
        authorization: accessToken,
      });

      const newToken = response?.data?.accessToken;
      if (newToken) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            accessToken: newToken,
          })
        );
      } else {
        const logoutRes = await authLogout();
        if (!logoutRes?.data?.refreshToken) {
          doLogout();
        }
      }
    }
  };

  useEffect(() => {
    console.log("Loader loading", loaderLoading, loaderMessage);

    dispatch(fetchUserIp());
    dispatch(authenticateUser());
    dispatch(fetchPlansData(ipAddress));
    startTransition(() => {
      setIsVisible((prev) => !prev);
    });

    if (localStorage.getItem("user")) {
      checkAndRefreshToken();
    }
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setskeleton(false)
  //   }, [1200])

  // }, [skeleton]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Router>
        {/* {isPending && <div><SkeletonLoader variant="newQr" /></div>} */}

        <Suspense fallback={<SkeletonLoader variant="index" />}>
          <AnimatePresence exitBeforeEnter>
            <Routes>
              {/* Unauthenticated Routes */}
              {UNAUTHENTICATED_ROUTES.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <UnauthenticatedRoute isAuthenticated={isAuthenticated}>
                      <route.component />
                    </UnauthenticatedRoute>
                  }
                />
              ))}

              {/* Authenticated Routes */}
              {AUTHENTICATED_ROUTES.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    // <AuthenticatedRoute isAuthenticated={isAuthenticated}>
                    <route.component />
                    // </AuthenticatedRoute>
                  }
                />
              ))}

              {/* Fallback Route for 404 */}
              <Route
                path="*"
                element={isAuthenticated ? <DashboardPage /> : <Login />}
              />
            </Routes>
          </AnimatePresence>
        </Suspense>
      </Router>
      {loaderLoading && <LoadingComponent />}
    </>
  );
}

export default App;
