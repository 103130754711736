import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../config/axiosBaseQuery";
import STORE from "../constants/STORE";

// create a new domain
const createQrDomain = (builder) => {
  return builder.mutation({
    query(payload) {
      return {
        url: `/qr-domains`,
        method: "POST",
        data: payload,
      };
    },
  });
};

// get all domains
const getQrAllDomain = (builder) => {
  return builder.mutation({
    query({ userId }) {
      return {
        url: `/qr-domains/by-user-id/${userId}`,
        method: "GET",
      };
    },
  });
};

// get a domain by id
const getQrDomainById = (builder) => {
  return builder.mutation({
    query({ id }) {
      return {
        url: `/qr-domains/${id}`,
        method: "GET",
      };
    },
  });
};

// update domain by id
const updateQrDomain = (builder) => {
  return builder.mutation({
    query({ id, payload }) {
      return {
        url: `/qr-domains/${id}`,
        method: "PATCH",
        data: payload,
      };
    },
  });
};

// delete domain by id
const deleteQrDomain = (builder) => {
  return builder.mutation({
    query({ id }) {
      return {
        url: `/qr-domains/${id}`,
        method: "DELETE",
      };
    },
  });
};

// verify domain by id
const verifyQrDomain = (builder) => {
  return builder.mutation({
    query({ id }) {
      return {
        url: `/qr-domains/${id}/verify`,
        method: "PATCH",
      };
    },
  });
};

const qrDomainsApi = createApi({
  reducerPath: STORE.REDUCER_PATHS.QR_DOMAIN_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createQrDomain: createQrDomain(builder),
    getQrAllDomain: getQrAllDomain(builder),
    getQrDomainById: getQrDomainById(builder),
    updateQrDomain: updateQrDomain(builder),
    deleteQrDomain: deleteQrDomain(builder),
    verifyQrDomain: verifyQrDomain(builder),
  }),
});

const {
  useCreateQrDomainMutation,
  useGetQrAllDomainMutation,
  useGetQrDomainByIdMutation,
  useUpdateQrDomainMutation,
  useDeleteQrDomainMutation,
  useVerifyQrDomainMutation,
} = qrDomainsApi;

export {
  qrDomainsApi,
  useCreateQrDomainMutation,
  useGetQrAllDomainMutation,
  useGetQrDomainByIdMutation,
  useUpdateQrDomainMutation,
  useDeleteQrDomainMutation,
  useVerifyQrDomainMutation,
};
