import { Link } from "react-router";
import { Settings, Power } from "lucide-react";
import { FaUser } from "react-icons/fa";
import Button from "../global/Button";
import { RiAdminFill } from "react-icons/ri";
import { useEffect } from "react";



const SubMenuSetting = () => {
  return (
    <>
      <div className="bg-[var(--darker-bg)] px-2 py-4 shadow-md z_index rounded-sm absolute top-[60px] right-[1px]">
        <ul className="flex flex-col gap-2">
          <Link to={"/settings"}>
            <li className="flex gap-2 text-[var(--text-light)]">
              <Settings className="text-[var(--primary)]" /> Setting
            </li>
          </Link>
          <Link to={"/Users"}>
          <li className="flex items-center gap-2 text-[var(--text-light)]">
            <FaUser className="text-[var(--primary)]" /> User
          </li>
          
          </Link>
          <li className="p-[1px] rounded-md bg-[var(--dark-bg)]"></li>
          <li className="flex items-center gap-2 text-[var(--lime-white)]">
            qrgen@gmail.com
          </li>
          <li className="flex items-center gap-2 text-[var(--text-light)]">
            admin
          </li>
          <li className="flex items-center gap-2 text-[var(--text-light)]">
            <Link to={"login"}>
              <Button
                text={"Logout"}
                iconOrder={"left"}
                icon={<Power className="text-[var(--primary)]" />}
                className={""}
              />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SubMenuSetting;