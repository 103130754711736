import { countries } from "countries-list";
import countryList, { getCode } from "country-list";

const getCountryName = () => {
  return countryList.getNames();
};

const getCountryCode = (countryName) => {
  const response = getCode(countryName);
  return response;
};

const getCountryCurrency = (countryName) => {
  const countryCode = getCode(countryName);
  if (!countryCode) return null;
  const countryData = countries[countryCode];
  return countryData?.currency[0];
};

const getCurrencySymbol = (currencyCode) => {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
  }).format(0);
  const symbol = formatted.replace(/[\d\.,\s]/g, "");
  return symbol;
};

export {
  getCountryName,
  getCountryCode,
  getCountryCurrency,
  getCurrencySymbol,
};
